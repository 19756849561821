.container {
    padding: 0 1.2rem 1.2rem;
}

.resultWrapper {
    display: grid;
    grid-template-columns: 60px 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    padding: .5rem 0;
}

.text {
    line-height: 1rem;
    font-size: 12px;
}
.center {
    text-align: center;
}